import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface CraState {
  tableData: any | null | undefined | any[];
  selectedData: any | null | undefined | any[];
  formDefaultValues: any | null | undefined;
  deactivateDivisionModalState: any | null | undefined;
  editMode: boolean;
  counter_company_location_id: string | null | undefined;
  adoptCounterCode: string | null | undefined;
  location_code: string | null | undefined;
  adoptDrType: string | null | undefined;
  PendingFilters: {
    division: any[] | undefined;
    city: any[] | undefined;
    company: any[] | undefined;
    link: any[] | undefined;
    status: any[] | undefined;
    state: any[] | undefined;
    district: any[] | undefined;
  };
  ApprovedByMeFilters: {
    division: any[] | undefined;
    city: any[] | undefined;
    company: any[] | undefined;
    link: any[] | undefined;
    status: any[] | undefined;
    state: any[] | undefined;
    district: any[] | undefined;
  };
  craFiltersData: {
    company: any[] | undefined;
    link: any[] | undefined;
    state: any[] | undefined;
    approvedByMeDivision: any[] | undefined;
    pendingDivision: any[] | undefined;
    approvedByMeDistrict: any[] | undefined;
    pendingDistrict: any[] | undefined;
    approvedByMeCity: any[] | undefined;
    pendingCity: any[] | undefined;
    approvedByMeStatus: any[] | undefined;
  };
}

const craSlice = createSlice({
  name: "cra",
  initialState: {
    selectedData: null,
    tableData: null,
    formDefaultValues: {},
    deactivateDivisionModalState: null,
    editMode: false,
    counter_company_location_id: null,
    adoptCounterCode: null,
    location_code: null,
    adoptDrType: null,
    ApprovedByMeFilters: {
      division: [],
      city: [],
      company: [],
      link: [],
      status: [],
      state: [],
      district: [],
    },
    PendingFilters: {
      division: [],
      city: [],
      company: [],
      link: [],
      status: [],
      state: [],
      district: [],
    },
    craFiltersData: {
      company: [],
      approvedByMeStatus: [],
      link: [],
      approvedByMeDivision: [],
      pendingDivision: [],
      state: [],
      approvedByMeDistrict: [],
      pendingDistrict: [],
      approvedByMeCity: [],
      pendingCity: [],
    },
  } as CraState,
  reducers: {
    setTableData(state, action: PayloadAction<string | null | undefined>) {
      state.tableData = action.payload;
    },
    setSelectedData(state, action: PayloadAction<any | null | undefined>) {
      state.selectedData = action.payload;
    },
    setFormDefaultValues(state, action: PayloadAction<any | null | undefined>) {
      state.formDefaultValues = action.payload;
    },
    setDeactivateDivisionModalState(state, action: PayloadAction<any>) {
      state.deactivateDivisionModalState = action.payload || {};
    },
    setEditMode(state, action: PayloadAction<any | null | undefined>) {
      state.editMode = action.payload;
    },
    setCounterCompanyLocationId(state, action: PayloadAction<string | null | undefined>) {
      state.counter_company_location_id = action.payload;
    },
    setAdoptCounterCode(state, action: PayloadAction<string | null | undefined>) {
      state.adoptCounterCode = action.payload;
    },
    setLocationCode(state, action: PayloadAction<string | null | undefined>) {
      state.location_code = action.payload;
    },
    setAdoptDrType(state, action: PayloadAction<string | null | undefined>) {
      state.adoptDrType = action.payload;
    },
    setCRAFilter(state, action: PayloadAction<{ isApprovedByMe: boolean; key: keyof typeof state.ApprovedByMeFilters; value: any[] | undefined }>) {
      const { isApprovedByMe, key, value } = action.payload;
      if (isApprovedByMe) {
        state.ApprovedByMeFilters[key] = value;
      } else {
        state.PendingFilters[key] = value;
      }
    },
    setCraFiltersData(state, action: PayloadAction<{ key: keyof typeof state.craFiltersData; value: any[] | undefined }>) {
      const { key, value } = action.payload;
      state.craFiltersData[key] = value;
    },
  },
});

export const craActions = craSlice.actions;
export { craSlice };
