import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import Table from "../../components/Table";
import { getCraColumns } from "../../constants";

import { Transition } from "@headlessui/react";
import { SortingState } from "@tanstack/react-table";
import { FilterIcon } from "lucide-react";
import { usePagination } from "src/hooks/usePagination";
import { getDistrictDetails } from "src/services/region-api";
import Button from "../../components/Button";
import CountersFilter from "../../container/CounterFilter";
import { craActions } from "../../context/cra-slice";
import { useOutsideClick } from "../../hooks/useOutsideClick";
import { queryCounterRequestBasedOnPosition } from "../../services/cda-api";
import { queryDivisionDetails } from "../../services/division-api";
import { appendParams } from "../../utils/utils";

const HomePendingTab = () => {
  const auth: any = useSelector((state: any) => state.auth);
  const [loading, setLoading] = useState(false);
  const { tableData } = useSelector((state: any) => state.cra);
  const { pagination, onPaginationChange, pageIndex, pageSize, resetPagination } = usePagination();
  const [totalRowCount, setTotalRowCount] = useState(10);
  const [isFiltersShown, setIsFiltersShown] = useState<boolean>(false);
  const [sorting, setSorting] = useState<SortingState>();
  const { craFiltersData, PendingFilters } = useSelector((state: any) => state.cra);
  const [globalSearch, setGlobalSearch] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const ref = useOutsideClick(() => {
    setIsFiltersShown(false);
  });

  const [_, setSearchParams] = useSearchParams();

  function rowClickHandler(row: any) {
    const rowData = row.original;

    const requestId = rowData?.counterRequestDetail?.counterRequest_dtl_id || "";

    window.sessionStorage.setItem("requestId", requestId);
    dispatch(craActions.setSelectedData({}));
    navigate("/request-approval/cra/details");
  }

  const fetchDetails = () => {
    if (!auth?.currentPsr?.emp_position_hdr?.position_code) return;
    setLoading(true);

    setSearchParams({
      pageSize: pageSize.toString(),
      pageIndex: pageIndex.toString(),
    });

    const { key, sortingOrder } = getSortingKey();

    const companyParams = appendParams(Array.isArray(PendingFilters?.company) ? PendingFilters.company : [], "company_id", "code");
    const divisionParams = appendParams(
      Array.isArray(PendingFilters?.division) ? PendingFilters.division : [],
      "division_id",
      "company_divisioncode"
    );
    const linkParams = appendParams(Array.isArray(PendingFilters?.link) ? PendingFilters.link : [], "linktype_id", "drph_lnk_type_id");

    const cityParams = appendParams(Array.isArray(PendingFilters?.city) ? PendingFilters.city : [], "city_id", "city_code");

    const stateParams = appendParams(Array.isArray(PendingFilters?.state) ? PendingFilters.state : [], "state_id", "state_code");

    const districtParams = appendParams(Array.isArray(PendingFilters?.district) ? PendingFilters.district : [], "district_id", "district_code");

    let filtersQuery = [companyParams, divisionParams, linkParams, cityParams, stateParams, districtParams].join("&");

    filtersQuery.endsWith("&&") && (filtersQuery = filtersQuery.slice(0, -1));
    filtersQuery.endsWith("&") && (filtersQuery = filtersQuery.slice(0, -1));

    queryCounterRequestBasedOnPosition(
      auth?.currentPsr?.emp_position_hdr?.position_code || "",
      auth?.currentPsr?.company_code,
      "type=pending",
      pageIndex - 1,
      key,
      sortingOrder,
      filtersQuery,
      globalSearch
    )
      .then((res) => {
        // const transformedData = res?.data?.data?.map((item: any) => flattenObject(item));
        dispatch(craActions.setTableData(res?.data?.data || []));
        setTotalRowCount(res?.data?.totalCount);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    // if (isMount.current === false) {
    //   isMount.current = true;
    //   return;
    // }

    fetchDetails();
  }, [auth?.currentPsr, pageSize, pageIndex]);

  let pageCount = Math.ceil(totalRowCount / (pageSize ?? 1));
  if (totalRowCount % 10 === 0) {
    pageCount--;
  }

  const getSortingKey = () => {
    const counterRequestCode = sorting?.find((item: any) => item.id === "counterRequestDetail_counter_request_code");
    const counterRequestName = sorting?.find((item: any) => item.id === "counterRequestDetail_counter_name");
    const createdDate = sorting?.find((item: any) => item.id === "created_date");
    if (counterRequestCode) {
      const sortingOrder = counterRequestCode?.desc ? "DESC" : "ASC";
      return {
        key: "counter_request_code",
        sortingOrder,
      };
    } else if (counterRequestName) {
      const sortingOrder = counterRequestName?.desc ? "DESC" : "ASC";
      return {
        key: "counter_name",
        sortingOrder,
      };
    } else if (createdDate) {
      const sortingOrder = createdDate?.desc ? "DESC" : "ASC";
      return {
        key: "created_date",
        sortingOrder,
      };
    } else {
      return {
        key: "created_date",
        sortingOrder: "DESC",
      };
    }
  };

  let timerId: any;
  const handleSearch = (searchQuery: string) => {
    if (timerId) clearTimeout(timerId);

    timerId = setTimeout(() => {
      resetPagination();
      // setSearch(`?pageIndex=1&pageSize=${pageSize}`);
      setGlobalSearch(searchQuery);
    }, 500);
  };

  useEffect(() => {
    fetchDetails();
  }, [sorting, globalSearch]);

  useEffect(() => {
    if (PendingFilters?.company?.length) {
      queryDivisionDetails(`/details/?fields=division_name&company_code=${PendingFilters?.company[0]?.code}`)
        .then((res) => dispatch(craActions.setCraFiltersData({ key: "pendingDivision", value: res.data })))
        .catch((err) => console.log(err));
    } else {
      dispatch(craActions.setCraFiltersData({ key: "pendingDivision", value: [] }));
      dispatch(craActions.setCRAFilter({ isApprovedByMe: false, key: "division", value: [] }));
    }
  }, [PendingFilters?.company]);

  useEffect(() => {
    if (PendingFilters?.state?.length) {
      getDistrictDetails(`?cityOnly=true&state_code=${PendingFilters?.state[0]?.state_code || ""}`).then((res) => {
        dispatch(craActions.setCraFiltersData({ key: "pendingDistrict", value: res.data.data }));

        const allCities = res.data.data.flatMap((district: any) => district.cities.map((city: any) => city));
        dispatch(craActions.setCraFiltersData({ key: "pendingCity", value: allCities }));
      });
    } else {
      dispatch(craActions.setCraFiltersData({ key: "pendingDistrict", value: [] }));
      dispatch(craActions.setCRAFilter({ isApprovedByMe: false, key: "district", value: [] }));
      dispatch(craActions.setCraFiltersData({ key: "pendingCity", value: [] }));
      dispatch(craActions.setCRAFilter({ isApprovedByMe: false, key: "city", value: [] }));
    }
  }, [PendingFilters?.state]);

  return (
    <Fragment>
      <Transition
        as={Fragment}
        show={isFiltersShown}
        enter="transition transition ease-in-out duration-200 transform"
        enterFrom="translate-x-full"
        enterTo="translate-x-0"
        leave="transition  ease-in-out duration-200 transform"
        leaveFrom="translate-x-0"
        leaveTo="translate-x-full"
      >
        <div className="fixed right-0 inset-0 top-0 z-[500]">
          <CountersFilter
            ref={ref}
            companyDetails={craFiltersData?.company || []}
            divisionDetails={craFiltersData?.pendingDivision || []}
            cityDetails={craFiltersData?.pendingCity || []}
            linkDetails={craFiltersData?.link || []}
            stateDetails={craFiltersData?.state || []}
            districtDetails={craFiltersData?.pendingDistrict || []}
            onCloseClick={() => setIsFiltersShown(false)}
          />
        </div>
      </Transition>

      {auth?.isAdmin && (
        <div className="flex justify-end float-right gap-2">
          <Button
            className="gap-1 px-4 mt-2 ml-2 border "
            onClick={() => {
              setIsFiltersShown(true);
            }}
          >
            Filters <FilterIcon className="w-5 h-5" />
          </Button>
          <Button onClick={fetchDetails} loaderSize="small" className="h-auto py-2 mt-2 border">
            Retrieve
          </Button>
        </div>
      )}

      <Table
        headClass="bg-[#dee2ff] text-[#586AF5]"
        columns={getCraColumns()}
        title="Pending Counter Requests"
        subtitle="View and manage pending counter requests"
        data={tableData ?? []}
        onRowClick={rowClickHandler}
        isLoading={loading}
        isGlobalSearch={true}
        manualPagination={true}
        pageCount={pageCount}
        pagination={pagination}
        onPaginationChange={onPaginationChange}
        sorting={sorting}
        onSortingChange={setSorting}
        manualSorting={true}
        onGlobalSearch={handleSearch}
      />
    </Fragment>
  );
};

export default HomePendingTab;
