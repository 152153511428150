import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useToast } from "src/hooks/useToast";
import Button from "../components/Button";
import Modal from "../components/Modal";
import { craActions } from "../context/cra-slice";
import { RootState } from "../context/store";
import { getIsActionAllowed, getPostAllowedLinkTypes } from "../services/cda-api";
import { getCounterLinkType } from "../services/counter-api";
import Spinner from "../components/Spinner.tsx";
import AdoptCounterForm from "./AdoptCounterForm";
import CounterCreateForm from "./CounterCreateForm";
import CreateCounter from "./CreateCounter";
import DivisionSelect from "./DivisionSelect";
import FormSuccess from "./FormSuccess";
import CounterCompanySelect from "./CounterCompanySelect.tsx";

interface CounterFormProps {
  showAddEditForm: boolean;
  setShowAddEditForm: (show: boolean) => void;
}

const CounterForm: React.FC<CounterFormProps> = ({ showAddEditForm, setShowAddEditForm }) => {
  const [counterLinkType, setCounterLinkType] = useState<any[] | null>(null);
  const [doctorType, setDoctorType] = useState<any>({});
  const [selectedDivision, setSelectedDivisions] = useState<any>({});
  const { deactivateDivisionModalState } = useSelector((state: RootState) => state.cra);
  const { currentPsr, isAdmin } = useSelector((state: RootState) => state.auth);
  const [isAdoptForm, setIsAdoptForm] = useState<boolean>(false);
  const [isImportForm, setIsImportForm] = useState<boolean>(false);
  const [lowestEmployee, setLowestEmployee] = useState<any>({});
  const [fetchingAllowedLinkTypes, setFetchingAllowedLinkTypes] = useState<boolean>(false);
  const [selectedCompany, setSelectedCompany] = useState<any>({});
  const [postPerson, setPostPerson] = useState<any>({});

  const dispatch = useDispatch();
  const { toast } = useToast();

  const [modalStates, setModalStates] = useState({
    counterSelect: false,
    adoptForm: false,
    CounterForm: false,
    doctorForm: false,
    errorForm: false,
    successForm: false,
    companySelect: false,
  });

  const openModal = (modalName: string) => {
    setModalStates((prevState) => ({
      ...prevState,
      counterSelect: false,
      adoptForm: false,
      CounterForm: false,
      doctorForm: false,
      errorForm: false,
      successForm: false,
      companySelect: false,

      [modalName]: true,
    }));
  };

  const closeModal = (modalName: string) => {
    setModalStates((prevState) => ({
      ...prevState,
      [modalName]: false,
    }));
  };

  const resetForm = () => {
    setShowAddEditForm(false);
    setIsAdoptForm(false);
    setIsImportForm(false);
    setDoctorType({});
    setModalStates({
      counterSelect: false,
      adoptForm: false,
      CounterForm: false,
      doctorForm: false,
      errorForm: false,
      successForm: false,
      companySelect: false,
    });
  };

  async function checkIfActionIsAllowed(company_divisioncode: any, currentLowestPSR: any, postPerson?: any) {
    try {
      let query = "";
      const position_code = isAdmin ? postPerson ? postPerson?.emp_position_hdr?.position_code : currentLowestPSR?.emp_position_hdr?.position_code : currentPsr?.emp_position_hdr?.position_code;

      const company_code = company_divisioncode?.company_code || company_divisioncode?.code;

      query = `?position_code=${position_code}&counter_linktype=${doctorType?.dr_ph_lnk_code?.toString()}&division_code=${company_divisioncode?.company_divisioncode?.toString()}&actiontype=post&company_code=${company_code}`;
      const res = await getIsActionAllowed(query);

      if (res?.status !== 200) {
        throw new Error();
      }

      if (!res?.data?.allowed) {
        throw new Error();
      }

      openModal("CounterForm");
    } catch (error) {
      openModal("errorForm");
      // openModal("CounterForm");
      dispatch(craActions.setDeactivateDivisionModalState({ type: false }));
    }
  }

  async function getAllowedLinkTypes(company_divisioncode: any, currentLowestPSR: any, linkTypes?: any[]) {
    try {
      setFetchingAllowedLinkTypes(true);
      let query = "";
      const position_code = isAdmin ? currentLowestPSR?.emp_position_hdr?.position_code : currentPsr?.emp_position_hdr?.position_code;
      const company_code = company_divisioncode?.company_code || company_divisioncode?.code;
      if (!linkTypes || linkTypes?.length === 0)
        query = `?position_code=${position_code}&division_code=${company_divisioncode?.company_divisioncode?.toString()}&company_code=${company_code}`;
      else
        query = `?position_code=${position_code}&division_code=${company_divisioncode?.company_divisioncode?.toString()}&company_code=${company_code}&counter_linktypes=${linkTypes
          ?.map((item: any) => item?.dr_ph_lnk_code)
          .join(",")}`;

      const res = await getPostAllowedLinkTypes(query);
      setCounterLinkType(res?.data);
      setFetchingAllowedLinkTypes(false);
    } catch (error) {
      console.log(error);
      setFetchingAllowedLinkTypes(false);
      toast({
        description: "Failed to fetch allowed link types. Please try again.",
        variant: "destructive",
      });
    }
  }

  const handleDoctorTypeSelect = () => {
    if (!doctorType || (doctorType && !Object.keys(doctorType)?.length)) {
      toast({
        description: "Please select a type.",
        variant: "destructive",
      });
      return;
    }

    dispatch(craActions.setAdoptDrType(doctorType?.dr_ph_lnk_code));
    if (isAdmin) {
      openModal("companySelect");
      closeModal("doctorForm");
    } else {
      closeModal("doctorForm");
      openModal("CounterForm");
    }
  };

  const fetchCounterLinkTypes = () => {
    getCounterLinkType("?cra=true")
      .then((res) => {
        setCounterLinkType(res?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (showAddEditForm) openModal("counterSelect");
  }, [showAddEditForm]);

  return (
    <>
      {/* initial counter select form  */}
      {modalStates.counterSelect && (
        <Modal
          isOpen={modalStates.counterSelect}
          isHeader={true}
          onCloseModal={() => {
            closeModal("counterSelect");
            resetForm();
          }}
          title="Select Type"
        >
          <CreateCounter
            onSubmit={(data: any) => {
              if (data === "createCounter") {
                if (isAdmin) {
                  fetchCounterLinkTypes();
                  openModal("doctorForm");
                } else dispatch(craActions.setDeactivateDivisionModalState({ type: true }));
              }
              if (data === "adoptForm") openModal("adoptForm");
              closeModal("counterSelect");
            }}
            onCancel={() => {
              setShowAddEditForm(false);
              resetForm();
            }}
          />
        </Modal>
      )}

      {modalStates.adoptForm && (
        <Modal
          isOpen={modalStates.adoptForm}
          isHeader={true}
          onCloseModal={() => {
            closeModal("adoptForm");
            resetForm();
          }}
          title="Adopt Counter"
        >
          <AdoptCounterForm
            onSubmit={(doctor: any) => {
              setDoctorType(doctor?.drph_lnk_type);
              dispatch(craActions.setDeactivateDivisionModalState({ type: true }));
              setIsAdoptForm(true);
              setIsImportForm(false);
              closeModal("adoptForm");
            }}
            onCancel={() => {
              resetForm();
              setIsAdoptForm(false);
              setIsImportForm(false);
            }}
          />
        </Modal>
      )}

      {modalStates.doctorForm && (
        <Modal
          isOpen={modalStates.doctorForm}
          onCloseModal={() => {
            resetForm();
            closeModal("doctorForm");
          }}
          isHeader={true}
          title="Create a counter"
        >
          <>
            <div className="w-[25rem] flex flex-col gap-3 px-5 py-4">
              {fetchingAllowedLinkTypes && (
                <div className="flex items-center justify-center">
                  <Spinner />
                </div>
              )}
              {counterLinkType?.map((item) =>
                item?.dr_ph_lnk_code !== "DRNT" ? (
                  <button
                    key={item?.dr_ph_lnk_code}
                    onClick={() => setDoctorType(item || "DRDS")}
                    className={`border w-full border-[#E9E9E9] py-4 px-3 rounded-xl hover:bg-[#F3EFFC] hover:border-[#586AF5] ${
                      doctorType?.dr_ph_lnk_code === item.dr_ph_lnk_code ? "bg-[#F3EFFC] border-[#586AF5]" : ""
                    }`}
                  >
                    {item?.dr_ph_lnk_desc} ({item?.dr_ph_lnk_code})
                  </button>
                ) : (
                  ""
                )
              )}
              {counterLinkType?.length === 0 && !fetchingAllowedLinkTypes && (
                <p className="text-sm font-semibold text-center text-red-500">No counter type found</p>
              )}

              <footer className="flex justify-end gap-2 mt-4 mb-2">
                <Button
                  onClick={() => {
                    resetForm();
                  }}
                  className="border"
                >
                  Cancel
                </Button>
                <Button onClick={handleDoctorTypeSelect} className="text-white border bg-[#586AF5]">
                  Confirm
                </Button>
              </footer>
            </div>
          </>
        </Modal>
      )}

      {deactivateDivisionModalState?.type && (
        <DivisionSelect
          onCloseModal={() => {
            resetForm();
          }}
          selectedCompany={selectedCompany}
          onConfirm={(data: any) => {
            const { selectedDivisions, currentLowestPSR, postPerson } = data;
            setSelectedDivisions(selectedDivisions);
            setLowestEmployee(currentLowestPSR);
            setPostPerson(postPerson);
            if (isAdmin) {
              // openModal("CounterForm");
              checkIfActionIsAllowed(selectedDivisions, currentLowestPSR, postPerson);
            } else if (!isAdoptForm) {
              openModal("doctorForm");
              getAllowedLinkTypes(selectedDivisions, currentLowestPSR);
            } else {
              checkIfActionIsAllowed(selectedDivisions, currentLowestPSR);
            }
            dispatch(craActions.setDeactivateDivisionModalState({ type: false }));
          }}
        />
      )}

      {modalStates.CounterForm && (
        <Modal
          isOpen={modalStates.CounterForm}
          isHeader={true}
          onCloseModal={() => {
            closeModal("CounterForm");
            resetForm();
          }}
          title={`${isAdoptForm ? "Adopt" : "Create"}  Counter  ${doctorType?.dr_ph_lnk_code ? `- ${doctorType?.dr_ph_lnk_code}` : ""}`}
        >
          <CounterCreateForm
            onCancel={resetForm}
            selectedDivision={selectedDivision}
            isAdoptForm={isAdoptForm}
            isImportForm={isImportForm}
            lowestEmployee={lowestEmployee}
            postPerson={postPerson}
            type={doctorType}
            onFormSubmit={() => {
              resetForm();
            }}
          />
        </Modal>
      )}

      {modalStates.errorForm && (
        <FormSuccess
          showForm={modalStates.errorForm}
          onContinue={() => resetForm()}
          variant="error"
          popupMessage={{
            description: "You don't have permission to perform this action",
            title: "Access Denied!",
          }}
        />
      )}

      {modalStates.successForm && (
        <FormSuccess
          showForm={modalStates.successForm}
          onContinue={() => resetForm()}
          popupMessage={{
            description: "Counter has been successfully updated",
            title: "Created Successfully!",
          }}
        />
      )}

      {modalStates.companySelect && (
        <CounterCompanySelect
          onSelect={(company: any) => {
            setSelectedCompany(company);
            dispatch(craActions.setDeactivateDivisionModalState({ type: true }));
            closeModal("companySelect");
          }}
          onCancel={() => {
            resetForm();
          }}
        />
      )}
    </>
  );
};

export default CounterForm;
